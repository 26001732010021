import React from "react";
import Reveal from "react-reveal";
const AppFeatures = () => {
  return (
    <section className="app_featured_area_two" id="storers">
      <div className="triangle_shape"></div>
      <div className="container">
        <div className="row app_feature_info">
          <div className="col-lg-5">
            <div className="app_img">
              {/* <div className="dot dot_one wow fadeIn" data-wow-delay="0.5s">
                <span className="dot1"></span>
                <span className="dot2"></span>
              </div>
              <div className="dot dot_two wow fadeIn" data-wow-delay="0.8s">
                <span className="dot1"></span>
                <span className="dot2"></span>
              </div>
              <div className="dot dot_three wow fadeIn" data-wow-delay="0.3s">
                <span className="dot1"></span>
                <span className="dot2"></span>
              </div> */}
              {/* <Reveal effect="fadeInLeft" duration={2500}>
                <img
                  className="text_bg one"
                  src={require("../../img/home7/text_one.png")}
                  alt=""
                />
              </Reveal>
              <Reveal effect="fadeInDown" duration={2000}>
                <img
                  className="text_bg two"
                  src={require("../../img/home7/text_three.png")}
                  alt=""
                />
              </Reveal>
              <Reveal effect="fadeInRight" duration={1500}>
                <img
                  className="text_bg three"
                  src={require("../../img/home7/text_two.png")}
                  alt=""
                />
              </Reveal> */}
              <img
                className="wow fadeIn phone_img"
                src={require("../../img/home7/screenshot_storer.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <Reveal bottom cascade>
              <div className="app_featured_content">
                <h2 className="f_p f_size_40 f_700 t_color3 l_height45 mb-30">
                  Add convenience, ease and organization to your stuff.
                </h2>
                <div display="flex">
                  <table>
                    <tr>
                      <h3 className="f_p f_400 f_size_24 numberCircle">1</h3>

                      <th>
                        <h3 className="f_p f_400 f_size_24 lineCategory ">
                          Store by the item
                        </h3>
                      </th>
                    </tr>
                    <td></td>
                    <td>
                      <p style={{ marginLeft: 20 + "px" }}>
                        Stop paying for empty space - pay for only what you're
                        storing with the ease of our automatic billing.
                      </p>
                    </td>

                    <tr>
                      <h3 className="f_p f_400 f_size_24 numberCircle">2</h3>

                      <th>
                        <h3 className="f_p f_400 f_size_24 lineCategory">
                          No more forgetting what you've stored
                        </h3>
                      </th>
                    </tr>
                    <td></td>
                    <td>
                      <p style={{ marginLeft: 20 + "px" }}>
                        Keep track of your items with our handy organizer. Take
                        pictures and keep notes of what you've stored.
                      </p>
                    </td>

                    <tr>
                      <h3 className="f_p f_400 f_size_24 numberCircle">3</h3>

                      <th>
                        <h3 className="f_p f_400 f_size_24 lineCategory">
                          Store your stuff near you
                        </h3>
                      </th>
                    </tr>
                    <td></td>
                    <td>
                      <p style={{ marginLeft: 20 + "px" }}>
                        Stop travelling to store your things. With all of our
                        hosts nearby, keep your stuff near you, not hidden far
                        away.
                      </p>
                    </td>

                    <tr>
                      {/* <td>
                      <p className="f_400">
                        And not pay a penny more. Storeo is here to make storage
                        affordable and convenient.
                      </p>
                    </td> */}

                      {/* <h3 className="f_p f_400 f_size_24">Track it</h3>
                    <h3 className="f_p f_400 f_size_24">Organize it</h3> */}
                    </tr>
                  </table>
                </div>

                {/* <a href=".#" className="app_btn btn_hover mt_40">Learn More</a> */}
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppFeatures;
