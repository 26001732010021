import React from "react";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import PrivacyPolicy from "../components/Privacy/PrivacyPolicy";
import AppHeader from "../components/Header/AppHeader";

const Privacy = () => {
  return (
    <div className="body_wrapper">
      <AppHeader />
      <PrivacyPolicy />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Privacy;
