import React, { Component } from "react";
import Sticky from "react-stickynode";
import { NavLink } from "react-router-dom";

class AppHeader extends Component {
  render() {
    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one`}>
            <div className={`container`}>
              <NavLink exact className={`navbar-brand`} to="/">
                <img src={require("../../img/logo2.png")} alt="" />
                <img src={require("../../img/logo.png")} alt="logo" />
              </NavLink>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default AppHeader;
