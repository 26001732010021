import React from "react";
import Reveal from "react-reveal";
const AppFeatures = () => {
  return (
    <section className="app_featured_area_two" id="hosts" display="flex">
      <div className="triangle_shape"></div>
      <div className="container">
        <div className="row app_feature_info" display="flex">
        <div className="col-lg-5">
            <div className="app_img" id="host-benefits-img">
            
              <img
                className="wow fadeIn phone_img"
                src={require("../../img/home7/screenhot_host.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 align-items-center" id="host-benefits-desc">
            <Reveal bottom cascade>
              <div className="app_featured_content">
                <h2 className="f_p f_size_40 f_700 t_color3 l_height45 mb-30">
                  Make passive income without the hassle.
                </h2>
                <div display="flex">
                  <table>
                    <tr>
                      <h3 className="f_p f_400 f_size_24 numberCircle">1</h3>

                      <th>
                        <h3 className="f_p f_400 f_size_24 lineCategory ">
                          Automated payments and renewals
                        </h3>
                      </th>
                    </tr>
                    <td></td>
                    <td>
                      <p style={{ marginLeft: 20 + "px" }}>
                      Make your space work for you. Set up your listing and add your banking information and you are on your way to passive income with automated payments throughout the month. 
                      </p>
                    </td>

                    <tr>
                      <h3 className="f_p f_400 f_size_24 numberCircle">2</h3>

                      <th>
                        <h3 className="f_p f_400 f_size_24 lineCategory">
                          Store items from multiple storers at once
                        </h3>
                      </th>
                    </tr>
                    <td></td>
                    <td>
                      <p style={{ marginLeft: 20 + "px" }}>
                       And see your income multiply. With trusted Storers, there no need to limit yourself to just one Storer at a time.  
                      </p>
                    </td>

                    <tr>
                      <h3 className="f_p f_400 f_size_24 numberCircle">3</h3>

                      <th>
                        <h3 className="f_p f_400 f_size_24 lineCategory">
                        Dedicated help from Storeo
                        </h3>
                      </th>
                    </tr>
                    <td></td>
                    <td>
                      <p style={{ marginLeft: 20 + "px" }}>
                      Storeo is here to clarify, inspire and get you to your passive income.
                      </p>
                    </td>

                    <tr>
                      {/* <td>
                      <p className="f_400">
                        And not pay a penny more. Storeo is here to make storage
                        affordable and convenient.
                      </p>
                    </td> */}

                      {/* <h3 className="f_p f_400 f_size_24">Track it</h3>
                    <h3 className="f_p f_400 f_size_24">Organize it</h3> */}
                    </tr>
                  </table>
                </div>

                {/* <a href=".#" className="app_btn btn_hover mt_40">Learn More</a> */}
              </div>
            </Reveal>
          </div>
         
        
        </div>
      </div>
    </section>
  );
};
export default AppFeatures;
