const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            shape : 'icon_shape1.png',
            icon : 'ti-panel',
            text : 'Export Presets',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 2,
            shape : 'icon_shape2.png',
            icon : 'ti-layout-grid2',
            text : 'Grid and Guides',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 3,
            shape : 'icon_shape3.png',
            icon : 'ti-gallery',
            text : 'Prototyping',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 4,
            shape : 'icon_shape4.png',
            icon : 'ti-fullscreen',
            text : 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safer',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flexible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Convinient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Export Presets',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'panel',
            rclass : 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles : 'Grid and Guides',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : 'Pixel Precision',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
        {
            id: '4',
            HRtitles : 'Vector Editing',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_four',
        },
        {
            id: '5',
            HRtitles : 'Cloud Service',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'cloud-down',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_five',
        },
        {
            id: '6',
            HRtitles : 'Iterate at Speed',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'bolt',
            rclass : 'pl_70',
            iclass: 'icon_six',
        }
    ],
    rpost:[
        {
            id: '01',
            image: 'blog-grid/post_1.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '02',
            image: 'blog-grid/post_2.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        {
            id: '03',
            image: 'blog-grid/post_3.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'jan 10, 2019'
        },
        {
            id: '04',
            image: 'blog-grid/post_4.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-target three',
            title: '1. Find Hosts Nearby',
            p: "Find nearby Hosts who can store your stuff. Decide on the type of area and features to store your items.",
        },
        {
            id: '02',
            icon: 'ti-hand-open',
            title: '2. Make the Reservation',
            p: "Make a reservation with a Host and schedule a time to drop off your stuff at your convenience.",
        },
        {
            id: '03',
            icon: 'ti-alarm-clock',
            title: '3. Arrange for Retrieval',
            p: "When it's time to pick up your stuff for any occasion or time, speak to your Host, and arrange time for retrieval.",
        },
        {
            id: '04',
            icon: 'ti-control-forward',
            title: '4. Do It Again',
            p: "Make MORE space in the sanctuary you call home all through the year.",
        }
    ]
}
export default ServiceData;