import React, { Component } from "react";
import Plx from "react-plx";
import Reveal from "react-reveal";

class AppFeaturesTwo extends Component {
  render() {
    const exampleParallaxData = [
      {
        start: 700,
        duration: 100,
        properties: [
          {
            startValue: -100,
            endValue: -20,
            property: "translateY",
          },
          {
            startValue: 1,
            endValue: 20,
            property: "translateX",
          },
          {
            startValue: 0,
            endValue: 20,
            property: "translate",
          },
          {
            startValue: 0,
            endValue: 2,
            property: "translateY",
          },
        ],
      },
    ];
    const exampleParallaxDatas = [
      {
        start: 700,
        duration: 100,
        properties: [
          {
            startValue: 100,
            endValue: 30,
            property: "translateY",
          },
          {
            startValue: 1.5,
            endValue: -20,
            property: "translateX",
          },
          {
            startValue: 0,
            endValue: 20,
            property: "translate",
          },
          {
            startValue: 0,
            endValue: 5,
            property: "translateY",
          },
        ],
      },
    ];
    return (
      <section className="app_featured_area" id="features">
        <div className="container">
          <div className="row flex-row-reverse app_feature_info">
            {/* <div className="col-lg-6">
                            <div className="app_fetured_item">
                                <Plx className="app_item item_one" parallaxData={exampleParallaxData}>
                                    <i className="ti-money f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">Save Money</h6>
                                </Plx>
                                <Plx className="app_item item_two"  parallaxData={exampleParallaxDatas}>
                                    <i className="ti-thumb-up f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">More Convenience</h6>
                                </Plx>
                                <div className="app_item item_three" data-parallax='{"x": 50, "y": 10}'>
                                    <i className="ti-face-smile f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">Clean Up Clutter</h6>
                                </div>
                                <div className="app_item item_four" data-parallax='{"x": -20, "y": 50}'>
                                    <i className="ti-pulse f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">Take Charge</h6>
                                </div>
                            </div>
                        </div> */}
            <div className="col-lg-12">
              <Reveal bottom cascade>
                <div className="app_featured_content">
                  <h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">
                    At Storeo, we come to work every day because we want to
                    solve the biggest problem in storage.
                  </h2>
                  <h2 className="f_p f_size_30 f_700 t_colornew l_height30 pr_70 mb-30">
                    Traditional self storage solutions do not work.
                  </h2>
                  {/* <p className="f_400">
                    High rise dwellers don’t know where to start or finish when
                    it comes to storing their stuff. Self storage areas are far away
                    and very expensive. Storers don’t exactly remember what they
                    have stored, and eventually lose their willingness (and a
                    lot of dollars) to retrieve their stored items when they
                    really need it.{" "}
                  </p> */}
                  <p className="f_400">
                    Our vision at Storeo is to empower everyone to take charge
                    of their surroundings. We are strong believers that our
                    surroundings and environment can influence our mental state
                    and well-being.
                  </p>
                  <p className="f_400">
                    We are building a vibrant marketplace of
                    Storers and Hosts to share their storage space amongst
                    each other. Storeo's state-of-the-art platform is here to
                    make this connection happen. Download our app now and join the marketplace revolution.
                  </p>
                  {/* <a href=".#" className="learn_btn_two">See All Featureds <i className="ti-arrow-right"></i></a> */}
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default AppFeaturesTwo;
