import React, {Component} from 'react';
import Slider from 'react-slick';
class AppTestimonial extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
    this.slider.slickNext();
    }
    previous() {
    this.slider.slickPrev();
    }
   render(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return(
        <section className="app_testimonial_area" id="testimonial">
            <div className="text_shadow" data-line=""></div>
                <div className="container nav_container">
                    <div className="slider_nav">
                        <i className="ti-arrow-left prev" onClick={this.previous}></i>
                        <i className="ti-arrow-right next" onClick={this.next}></i>
                    </div>
                    <div className="shap one"></div>
                    <div className="shap two"></div>
                    <Slider ref={c => (this.slider = c)} className="app_testimonial_slider" {...settings}>
                        <div className="app_testimonial_item text-center">
                            <div className="author-img"><img src={require('../../img/home7/testimonial_1.png')} alt=""/></div>
                            <div className="author_info">
                                <h6 className="f_p f_500 f_size_18 t_color3 mb-0">Levi Cristobal</h6>
                                <p>Claims Ajudicator</p>
                            </div>
                            <p className="f_400">I absolutely support Storeo's vision of making space in everyone's home. Living in a condo downtown, I sometimes struggle with not having enough space to store my everyday things, let alone my bicycle that I use in the summertime. I look forward using the Storeo app this summer.</p>
                        </div>
                        <div className="app_testimonial_item text-center">
                            <div className="author-img"><img src={require('../../img/home7/testimonial_4.jpg')} alt=""/></div>
                            <div className="author_info">
                                <h6 className="f_p f_500 f_size_18 t_color3 mb-0">Colin Dawes</h6>
                                <p>Chief Technology Officer</p>
                            </div>
                            <p className="f_400">As a CTO, I know good technology when I see one. After taking a look at Storeo's app, I know that this will be a winner. It functions seamlessly, uses the latest development frameworks, and integrates meaningful UI. I will be one of the early adopters of this app, because I know how much potential it has. </p>
                        </div>

                        <div className="app_testimonial_item text-center">
                            <div className="author-img"><img src={require('../../img/home7/testimonial_6.png')} alt=""/></div>
                            <div className="author_info">
                                <h6 className="f_p f_500 f_size_18 t_color3 mb-0">Francis Ng</h6>
                                <p>Real Estate Agent</p>
                            </div>
                            <p className="f_400"> Working in the real estate industry, I constantly come across people living with restrictions and constraints on space. Storeo's business concept is an eye-opener. This app may very well be addressing a fundamental problem faced by thousands who simply think it is a dead-end issue. I can foresee quick adoption once this app is released. You are a space-saver! </p>
                        </div>
                        <div className="app_testimonial_item text-center">
                            <div className="author-img"><img src={require('../../img/home7/testimonial_2.jpg')} alt=""/></div>
                            <div className="author_info">
                                <h6 className="f_p f_500 f_size_18 t_color3 mb-0">Kevin Wong</h6>
                                <p>Pharmaceutical Consultant</p>
                            </div>
                            <p className="f_400">When I heard about Storeo, I immediately knew how much value that it can bring to it's customers. Since the birth of my son, my condo has been packed to the brim! I'm curious to know more about what Storeo has to offer, and will definitely be on the platform once it's released. </p>
                        </div>
                        <div className="app_testimonial_item text-center">
                            <div className="author-img"><img src={require('../../img/home7/testimonial_5.jpg')} alt=""/></div>
                            <div className="author_info">
                                <h6 className="f_p f_500 f_size_18 t_color3 mb-0">Sania Mirza</h6>
                                <p>Product Manager</p>
                            </div>
                            <p className="f_400">Space is a luxury nowadays. I live in a condominium downtown, and the moment I heard about Storeo, I knew I wanted to store my things with Storeo right away. There is so much potential in this business idea, and knowing more about their leadership and strategy, I know it will be a success. </p>
                        </div>
                        
                        <div className="app_testimonial_item text-center">
                            <div className="author-img"><img src={require('../../img/home7/testimonial_3.jpg')} alt=""/></div>
                            <div className="author_info">
                                <h6 className="f_p f_500 f_size_18 t_color3 mb-0">Jerome Wong</h6>
                                <p>Process Manager</p>
                            </div>
                            <p className="f_400">I moved to Dallas recently with my family from Toronto. It was ridiculously difficult to pack my things and have a trusted affordable place to store it. I really wish Storeo's service was around then. But I can't wait to have access to their platform and marketplace and store my things.  </p>
                        </div>

                        
                    </Slider>
                </div>
            </section>
        )
   }
}
export default AppTestimonial;