import React from "react";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import AppHeader from "../components/Header/AppHeader";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";

const TermsAndConditionsPage = () => {
  return (
    <div className="body_wrapper">
      <AppHeader />
      <TermsAndConditions />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default TermsAndConditionsPage;
