import React from "react";
import OnepageMenu from "../components/OnepageMenu";
import AppBanner from "../components/Banner/AppBanner";
import AppFeaturesTwo from "../components/Features/AppFeaturesTwo";
import AppFeatures from "../components/Features/AppFeatures";
import AppTestimonial from "../components/Testimonial/AppTestimonial";
import AppWork from "../components/Service/AppWork";
import ScreenshowCase from "../components/ScreenshowCase";
import FooterTwo from "../components/Footer/FooterTwo";
import ServiceData from "../components/Service/ServiceData";
import FooterData from "../components/Footer/FooterData";
import HostFeatures from "../components/Features/HostBenefits";
// import Footer from "../components/Footer/Footer";


const Landing = () => {
  return (
    <div className="body_wrapper">
      
      <OnepageMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <AppBanner />
      <AppFeatures />
      <HostFeatures />
      <AppWork ServiceData={ServiceData} />
      <AppTestimonial />
      {/* <ScreenshowCase/> */}
      <AppFeaturesTwo />

      <FooterTwo FooterData={FooterData} />
      {/* <Footer FooterData={FooterData}/> */}
    </div>
  );
};
export default Landing;
