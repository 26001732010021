import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Slider from "react-slick";

class TermsAndConditions extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <section
        className="app_terms_and_conditions sec_pad pt_150"
        id="termsandconditions"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Storeo Terms and Conditions</title>
          <link
            rel="canonical"
            href="https://www.storeo.ca/termsandconditions"
          />
          <meta
            name="description"
            content="This is Storeo's Terms and Conditions outlined for all users, which may be updated from time to time."
          />
          <meta name="keywords" content="Storeo Terms and Conditions, Self Storage Marketplace Terms and Conditions, Peer to Peer Self Storage Rules, Self Storage Contract Details for Peer to Peer Usage" />
        </Helmet>
        <div className="col-lg-12">
          <div className="container p0">
            <h2>Terms and Conditions - Storeo Incorporated</h2>
            <p>Thank you for using Storeo!</p>
            <p>
              These terms and conditions outline the rules and regulations for
              the use of Storeo's Website and Mobile Application.
            </p>
            <address>
              Storeo Incorporated <br />
              Toronto, Canada
              <br />
              Last Updated: May 2022
              <br />
            </address>
            <p>
              <p>
                Please read these Terms of Service (“Terms”) carefully as they
                contain important information about your legal rights, remedies
                and obligations. By accessing or using the Storeo Platform
                (including web and mobile applications), you agree to comply
                with and be bound by these Terms. 
              </p>
            </p>
            <br />
            <h2>Acceptance of Terms</h2>
            <p>
              1. These Terms and Conditions form a legally binding agreement
              between you (“you”, “your”, the “User”, the “Host” or the
              “Storer”) and Storeo, Incorporated (“Storeo”), (“we”, “us”, “our”,
              or the “Company”) which governs your use of this platform (the
              “Platform”) including our Platform, mobile and web applications
              and our services. 
            </p>
            <p>
              2. Our privacy policy provides a comprehensive detail on what
              information is collected and stored.  
            </p>
            <p>
              3. By clicking “I ACCEPT”, you register as a user/ member of
              Storeo (“User”) and you enter a legally binding agreement with
              Storeo on the following terms and privacy policy. You warrant that
              you have full legal capacity to enter into this agreement. If you
              do not accept all the Terms and Conditions or Privacy Policy you
              must not use or access the Platform.  
            </p>
            <p>
              4. Storeo reserves the right to amend these Terms and Conditions
              from time to time with a notice sent to your specified email
              address, or your mobile application inbox. You will be deemed to
              have read and accepted these Terms and Conditions, including any
              changes, each time you use Storeo’s marketplace platform. 
            </p>
            <br />
            <h2>Terms and Definitions</h2>
            <p>
              5. Host: the person offering their available storage space for
              rent on the Storeo marketplace platform to a Storer 
            </p>
            <p>
              6. Space: the physical space at the Host's primary or secondary
              address in which individual items are stored under a Contract 
            </p>
            <p>
              7. Storer: the person paying a Rental Fee to a Host to use a Space
              to store items belonging to them or under their possession. 
            </p>
            <p>
              8. Listing: an advertisement or post on the Platform offering a
              Space for the storage of goods 
            </p>
            <p>
              9. Contract: Agreement between a Host and a Storer to utilize a
              Space for the storage of goods for a Fee 
            </p>
            <p>
              10. Stored Goods: means the goods and items to be stored under a
              Contract 
            </p>
            <p>
              {" "}
              11. Storeo Commission: A percentage fee paid by hosts from their
              monthly earnings.
            </p>
            <p>
              12. Service Fee: A service fee applied to Storers each month upon
              the usage of the Platform. 
            </p>
            <p>
              13. Total Fee: The fee for storing an item and the Service Fee.  
            </p>
            <p>
              14. Transportation Fee: A fee to an optional service for
              transportation services for Storers made available by the Host.  
            </p>
            <p>
              15. Prohibited Goods: The following items may not be stored under
              any circumstances: Toxic, polluted or contaminated goods;
              firearms, munitions, weapons or explosives; radioactive materials;
              highly flammable or hazardous goods; living plants or animals;
              alcohol or drugs, food or perishable goods (unless frozen food to
              be stored in a deep freezer); anything damp, moldy, rotten or
              infested with or damaged by parasites; any item which emits any
              fumes or strong odour; cash and securities; illegal goods; waste;
              or any item whose presence would invalidate any insurance policy
              which would otherwise cover the items or the storage space 
            </p>
            <p>16. Payment Manager: Stripe </p>
            <p>17. Identity Verification: Persona </p>
            <p>
              18. Money Back Guarantee. If a booking is made by a Storer, they
              are eligible for a money back guarantee should they choose to
              cancel their storage reservation within 24 hours. 
            </p>
            <br />
            <h2>General Conditions</h2>
            <p>
              19. Purpose of app: Storeo makes available a platform or
              marketplace for Storers and Hosts to list, quote and arrange for
              bookings of storage or other available space. The Platform is not
              a rental agent or property broker. Storeo acts as an intermediary
              for members to transact and is not considered a party to any
              Contract between the Host and the Storer. 
            </p>
            <p>
              20. We reserve the right to close your account or to remove or
              disable access to any Listing for any reason, including Listings
              that, in its sole discretion, considers to be objectionable for
              any reason, in violation of these Terms. 
            </p>
            <p>
              21. We reserve the right to change our Commission, Service and
              Transportation fees when we deem to do so, with adequate
              notifications to all of our patrons.  
            </p>
            <p>
              22. The laws of Canada and the United States of America apply to
              these Terms and Conditions and the parties submit exclusively to
              the courts of that jurisdiction. 
            </p>
            <br />
            <h2>Host and Storer Obligations</h2>
            <p>
              23. Registration with the Platform requires the User to either log
              in via third-party managed authentication provider or supply their
              name, and email address and a password. Once registered their
              email address and password constitute the “Registration Details”
              of your User account. 
            </p>
            <p>
              24. When you register as a host, we require you to verify your
              identity using third-party identity verification provider Persona.
              You warrant that all identity documents you provide are complete,
              current and accurate. You undertake to notify us of any changes to
              these identity documents within 7 days (for example, changing your
              name or contact address). 
            </p>
            <p>
              25. Each Listing by a Host is an offer to enter a legally binding
              Contract with the Storer. Storeo is not involved in this
              contract.  If the Storer makes a reservation of a Listing through
              the Platform, the Host will be notified by email or cloud
              notification of the booking reservation request, to which they
              must respond within 48 hours.  
            </p>
            <p>
              26. A nominal initial reservation amount is charged to the Storer
              to indicate their commitment to this reservation. This amount will
              be returned if the reservation is not accepted by the Host.  
            </p>
            <p>
              <p>
                27. In the absence of response from the Host, the reservation
                request is considered rejected, and the initial reservation
                amount is refunded to the Storer. If the Host accepts the
                reservation, additional notifications and emails are sent to the
                Storer about the next steps. Open communication is available on
                the Storeo Platform between Hosts and Storers to discuss other
                details such as time of day and instructions for access of
                storage.  
              </p>
              28. To create a Listing, the Host must describe the Space, along
              with any conditions of storage they wish to apply, on the listing
              page of the Platform. Such description may include text, graphics,
              pictures and other relevant content and should include details
              like size, location, security, etc. All descriptions and
              information must be accurate, complete, up-to-date and truthful to
              the best of your knowledge and belief. In the event that the Space
              becomes unavailable for any reason, you agree to remove or suspend
              the Listing as soon as reasonably practicable. 
            </p>
            <p>
              29. Hosts have the option to offer transportation to their
              Storers. This means and includes the transportation of Storer’s
              items from their location to the Host’s home (storage location).
              Hosts are responsible for the careful transport of items and
              assume the responsibility of ensuring these items arrive at the
              storage location safely.  
            </p>
            <p>
              30. Storeo has the right to maintain the pricing of each
              individual item for storage which cannot be modified by Hosts.
              This pricing may be subject to change by Storeo, and all patrons
              of Storeo will be notified of it.  
            </p>
            <p>
              31. Hosts can store items from multiple Storers simultaneously for
              the same storage location.   
            </p>
            <p>
              32. You as a Host acknowledge and agree that you are responsible
              for any and all Listings you post including its accuracy.
              Accordingly, you represent and warrant that any Listing you post
              and the booking of, or Storer's use of, a Space in a Listing you
              post (i) will not breach any agreements you have entered into with
              any third parties and (ii) will (a) be in compliance with all
              applicable laws, tax requirements, and rules and regulations that
              may apply to any Space included in a Listing you post, including,
              but not limited to, zoning laws and laws governing rentals of
              residential and other properties and (b) not conflict with the
              rights of third parties. 
            </p>
            <p>
              33. The Host needs to provide adequate time to access the storage
              space listing provided upon giving notice to and may monitor the
              Storer while they place their items on the Host’s property.  
            </p>
            <p>
              34. The Host is responsible for the restitution of goods stored
              and inventoried in the state they were in at the time of storage.
              The Host has the obligation to inform without delay the Storer for
              any loss, damage or theft he would have found on the deposited
              assets. The Host in this case is responsible for the restitution
              of the deposited property in the same condition they were on the
              day of delivery. 
            </p>
            <p>
              35. Hosts agree to never insist, ask, or insinuate to a Storer
              that he or she pay any or all of the Space Fees directly to Host
              with a check, cash, or any payment method other than via payment
              through Storeo. 
            </p>
            <p>
              36. In the event a Storer fails to make full payment to Storeo
              required under this agreement, the Host agrees to: a. Assign their
              rights as bailee to Storeo; b. Assist Storeo to the best of their
              ability by complying with any and all reasonable requests (such
              as, but not limited to: denying the Storer access to the property
              and their storage, taking images and an accurate inventory of the
              items in storage, allowing third party access to view the items in
              storage, and providing any reasonable information as requested by
              Storeo). Should the Host fail to comply with any reasonable
              request, this may (at the sole discretion of Storeo) be deemed
              sufficient to cancel the agreement and immediately cease all
              payments to the Host. Specific Obligations of Storers 
            </p>
            <p>
              37. By entering into a Contract with a Host you as the Storer
              warrant and represent that you will only use the space for the
              storage of personal property, you are not seeking storage of
              Prohibited Goods and will not use the Space for any unlawful
              purpose, and you will not do anything that will interfere with the
              Host’s reasonable enjoyment of the rest of the premises. 
            </p>
            <p>
              38. The Storer undertakes to pay the Fee promptly, fully and in
              good faith and using the online Payment Manager upon confirmation
              of the Contract with the Host. 
            </p>
            <p>
              39. Payment to Storeo of less than the amount owed does not reduce
              your obligation to pay the balance. Acceptance of this
              part-payment is not a waiver of any rights Storeo has under this
              agreement. 
            </p>
            <p>
              40. Should you fail to pay the full amount due, or if any payment
              is dishonoured or cancelled, you acknowledge that Storeo incurs
              internal and external costs, and you agree to pay all costs in
              full. 
            </p>
            <p>
              41. The Storer will comply with the access and security
              arrangements in the Contract or as otherwise agreed with the
              Host.  
            </p>
            <p>
              42. After storage for a particular item has been cancelled, the
              Storer must remove their storage item from the Host’s property on
              the arranged date and time. Storers must ensure that the Host’s
              storage property is left in the way it was presented to them.  
            </p>
            <p>
              43. Pursuant with clauses 43 through 47 of these Terms and
              Conditions, the Storer will remove the Stored Goods from the Space
              upon request by the Host, provided that the Host gives at least
              thirty (30) days' notice in writing of such request. 
            </p>
            <p>
              44. The Host may refuse you or any other person access to their
              premises, or your storage space (including after termination of
              your agreement) if: a. You haven’t complied with one or more of
              your obligations under this agreement; or b. You haven’t provided
              suitable identification to the host upon commencement of this
              agreement. 
            </p>
            <br />
            <h2>Obligation of Storeo</h2>
            <p>
              45. Upon acceptance of a Contract between a Host and a Storer,
              Storeo will collect Total Fees from the Storer. All financial
              transactions between the parties will be processed by a
              third-party electronic payment system (Stripe) embedded in the
              Platform. By using the Payment Manager, you also confirm that you
              have read and agree to the terms and conditions applying to that
              service. 
            </p>
            <p>
              46. Should the Host reject an initial reservation request made by
              the Storer, the initial reservation fee will be refunded to the
              Storer.  
            </p>
            <p>
              47. Storeo prorates the costs of storage should the Storer choose
              to end their storage. For the purposes of calculations, the number
              of days in a month are calculated as 30 days.  
            </p>
            <p>
              48. The Total Fees are calculated by Storeo and collected by the
              Payment Manager. Storeo will then initiate payment of Storeo Rent
              less the Storeo Commission to the Host either within one (1)
              business day of the Storer moving in, as per the booking start
              date.  
            </p>
            <p>
              49. Storeo will collect from the Storer an initial reservation
              amount at the time of booking confirmation. They will be charged
              the remaining amount at the end of the month.  
            </p>
            <p>
              50. Rental periods are automatically extended at the end of the
              month on a recurring basis until the Storer decides to terminate
              this lease.  If Recurring Payments apply to your payment
              obligations for Total Fees owed for a confirmed booking, you
              authorize Storeo, on behalf of the Host, to collect the Total Fees
              in the increments and at the frequency associated with the
              applicable Recurring Payments. Where a contract has been extended,
              Storeo will continue to collect Total Fees on a monthly recurring
              basis until the Storer or Host provides notice of termination of
              the lease. 
            </p>
            <br />
            <h2>Cancellations, Variations & Termination of Lease</h2>
            <p>
              51. Either User (Host or Storer) may cancel the Contract without
              penalty within 24 hours of the commencement date. 
            </p>
            <p>
              52. If a Storer has made a booking through Storeo, you are
              protected by our money back guarantee. You will be reimbursed if
              the issue is raised within 24 hours of the booking confirmation,
              or there is an error in the address or description of the space.
              This is known as the Money Back Guarantee Period. 
            </p>
            <p>
              53. If, as a Storer, you cancel your requested booking inside the
              Money Back Guarantee Period, Storeo will cancel any
              pre-authorization to your credit card and/or refund any nominal
              amounts charged to your credit card in connection with the
              requested booking. If, as a Host you cancel the requested booking
              inside the Money Back Guarantee Period, you will not be entitled
              to receive any Storeo Rental payments, and you must immediately
              repay any monies received in advance as consideration of the
              Contract being completed. 
            </p>
            <p>
              54. If, as a Storer, you wish to cancel or terminate the Contract
              outside of the Money Back Guarantee Period, you may request
              cancellation of your items to the Host. Upon accepting the
              cancellation from the host, your final bill will be prorated based
              on the number of days requested or in storage. The Host will
              receive the Storeo Rent which may be prorated for that period. 
            </p>
            <p>
              55. If, as a Host, you wish to cancel or terminate the Contract
              outside of the Money Back Guarantee Period you must provide thirty
              (30) days notice (“Notice Period”) to the Host and to Storeo of
              your intention to cancel or terminate the Contract. By cancelling
              the Contract, you acknowledge that you are breaking the terms of
              the Contract, agree to continue to store the Stored Goods for the
              Notice Period (if already located in the Space) and forfeit the
              Storeo Rent payable to you for the period of the Notice Period.
              Storeo may, at its absolute discretion, refund part or all of the
              Storeo Rent paid by the Storer for the Notice Period. 
            </p>
            <p>
              56. Clause 51 and 54 do not apply where the Contract is terminated
              due to non-payment of fees, or a party is in breach of its
              obligations under these Terms and Conditions or the Contract. 
            </p>
            <p>
              57. Termination conditions due to Prohibited Goods: If, as a Host,
              you have reasonable suspicion that a Storer is storing Prohibited
              Goods or is in breach of these Terms and Conditions, you have the
              right to immediately terminate the Contract and to ask the Storer
              to remove the items from the premises. The Host shall provide
              reasonable notice and opportunity for the Storer to cure such
              breach or otherwise remove such items. The Host may also take
              steps to inform law enforcement authorities and take actions to
              remove the items from their property. 
            </p>
            <p>
              58. Termination conditions due to Late Payments: In the event a
              Storer fails to make full payment by the due date required by
              Storeo under this agreement, the Storer agrees to: a. At 3 days
              overdue, the addition of a $30 late fee. This fee may be waived at
              the sole discretion of Storeo should the Storer make full payment
              before the end of business on the 7th day overdue. b. At 3 days
              overdue, the Storer will be denied access to the Space or Host's
              property until the default is cured. c. At 7 days overdue, Storeo
              claims, and you grant, a contractual lien over all your property
              stored on any and all properties under agreements with Storeo. d.
              At 14 days overdue, Storeo or the Host may immediately terminate
              the Contract and require the immediate removal of all items from
              the Space. c. At 28 days overdue, at the sole discretion of
              Storeo, consider all the items in the Space to be abandoned by the
              Storer and treated as such. 
            </p>
            <br />
            <h2>Abandoned Goods</h2>
            <p>
              59. If at any time: a. You fail to pay Storeo any amount you owe
              that is due, within 28 days of the due date; or b. You fail to
              remove your property when required to do so under this agreement;
              and You fail to rectify either despite receiving notification,
              your property will be deemed to be ‘abandoned goods’ for the
              purposes of this clause. 
            </p>
            <p>
              60. In the event that your goods are deemed to be ‘abandoned
              goods’ you acknowledge and agree that: a. It’s not reasonable for
              your goods to remain on the host’s premises and that Storeo will
              by necessity, need to deal with them. b. Storeo may dispose of
              (which may include dumping at a waste facility); or c. Find
              alternative storage for; or d. Sell any or all of your abandoned
              goods on terms Storeo deems reasonable.
            </p>
            <p>
              61. In the event that your goods are deemed to be ‘abandoned
              goods’ Storeo will incur further costs in dealing with them, you
              acknowledge this and agree to pay all costs. 
            </p>
            <p>
              62. You agree to indemnify Storeo, its directors, contractors,
              agents and employees from all damages, claims, action or liability
              that may eventuate from exercising the rights of Storeo in dealing
              with your abandoned goods. 
            </p>
            <br />
            <h2>Property Insurance</h2>
            <p>
              63. The Host is responsible for the Space and is expected to hold
              comprehensive home and contents insurance on the property on which
              the Space is located. Storeo recommends that the Host inform and
              confirm with their insurance provider the extension of their
              coverage under the terms of the Contract. 
            </p>
            <br />
            <h2>Storeo Platform Rules</h2>
            <p>
              64. You must follow these Platform rules and must not help or
              induce others to break or circumvent these rules.  
            </p>
            <p>65. Act with integrity and treat others with respect </p>
            <p>
              <ul>
                <li>
                  Do not lie, misrepresent something or someone, or pretend to
                  be someone else. 
                </li>
                <li>
                  Be polite and respectful when you communicate or interact with
                  others. 
                </li>
                <li>Do not discriminate against or harass others. </li>
              </ul>
            </p>
            <p>
              66. Do not scrape, hack, reverse engineer, compromise or impair
              the Storeo Platform 
            </p>
            <p>
              <ul>
                <li>
                  Do not use bots, crawlers, scrapers, or other automated means
                  to access or collect data or other content from or otherwise
                  interact with the Storeo Platform. 
                </li>
                <li>
                  Do not hack, avoid, remove, impair, or otherwise attempt to
                  circumvent any security or technological measure used to
                  protect the Storeo Platform or Content. 
                </li>
                <li>
                  Do not decipher, decompile, disassemble, or reverse engineer
                  any of the software or hardware used to provide the Storeo
                  Platform. 
                </li>
                <li>
                  Do not take any action that could damage or adversely affect
                  the performance or proper functioning of the Storeo Platform. 
                </li>
              </ul>
            </p>
            <br />
            <h2>Intellectual Property</h2>
            <p>
              67. The Platform and all intellectual property belonging to or
              associated with the Company, including any trademark or trade
              name, logos and software, and all content on the Platform
              (including, but without limitation, text, graphics, videos, music,
              sound, links, and software) is and remains at all times the
              property of the Company and/or is used under licence from its
              suppliers and is protected under international treaty provisions
              and worldwide copyright laws and you agree that you will not
              infringe any such rights in any way. 
            </p>
            <p>
              68. Except as expressly permitted by these Terms and Conditions,
              you may not copy, reproduce, redistribute, download, republish,
              transmit, display, adapt, alter, create derivative works from or
              otherwise extract or re-utilize any content in any way or on any
              medium (including other Platforms) without our prior written
              consent. Nor do we grant any express or implied right to you under
              any of our trademarks, copyrights or other proprietary rights. 
            </p>
            <p>
              69. The Company’s logo and any other image on the Platform which
              bears the Company’s name are trademarks of the Company. They may
              not be used without our prior written consent. 
            </p>
            <p>
              70. All information and content uploaded or otherwise sent, by any
              means, by you to the Platform or the Company or to other Users
              must not infringe any third party’s intellectual property or any
              other legal rights. You agree and warrant that you own or have
              permission from the owners to use any information or content you
              upload or send in the manner and for the purposes that you upload
              or send it. 
            </p>
            <p>
              71. When you upload or send information or other content to or via
              the Platform, you grant the Company a worldwide, royalty-free,
              sub-licensable, non-exclusive license to reproduce, edit, transmit
              and publish the information or content for the purposes of these
              Terms and Conditions. 
            </p>
            <br />
            <h2>Confidentiality</h2>
            <p>
              72. All Users must treat all information available and otherwise
              provided through the Storeo Platform as strictly confidential, and
              may only use that information for the purpose of organizing the
              storage of goods through Storeo. 
            </p>
            <p>
              73. Confidential Information expressly includes contact details of
              each User, address for storage of goods, and information about any
              goods in storage and does not include any information already in
              the public domain, or independently known to the User. 
            </p>
            <br />
            <h2>Privacy Policy</h2>
            <p>
              74. Storeo has a Privacy Policy which forms part of these Terms
              and Conditions. You must read and agree to the Privacy Policy
              prior to using the Platform. 
            </p>
            <br />
            <h2>Limitations and Indemnity</h2>
            <p>
              75. Storeo provides the Platform and its Services on an ‘as is’
              and ‘as available’ basis. All conditions, terms, representations
              and warranties that are not expressly stated in these terms and
              Conditions, whether oral or in writing or whether imposed by
              statute or operation of law or otherwise, including, without
              limitation, the implied warranty of satisfactory quality and
              fitness for a particular purpose are hereby excluded. 
            </p>
            <p>
              76. The Company gives no warranties, conditions, guarantees or
              representations in relation to the Platform or Services: a. as to
              the completeness or accuracy of any advice and information
              contained on or sent or received from the Platform or any Platform
              to which it is linked; b. of any particular result or outcome of
              using the Platform or Services, or that either will meet your
              requirements; or c. that the Platform or Services, and content on
              the Platform or the server that makes it available are error- or
              virus-free or free of other harmful components or that your use of
              the Platform will be uninterrupted, timely, secure, or
              error-free. 
            </p>
            <p>
              77. All Contracts between Users are carried out entirely at your
              own risk. The Company and its officers, directors, investors,
              contractors, agents and employees excludes to the fullest extent
              permitted by applicable law all liability for any claims, demands,
              damages, losses, costs and expenses, arising out of or in any way
              connected with any other arrangements that User(s) may have made
              in connection with this Platform, or the User’s use of the
              Platform. You rely on information provided by other Users at your
              own risk. The Company does not, control, inspect, endorse, approve
              or check the availability, condition or nature of advertised space
              or the accuracy, currency, truth or completeness of the
              information provided by Users of the Platform and it is your
              responsibility to do this and to take any other necessary
              precautions before arranging or carrying out a Contract. 
            </p>
            <p>
              78. You agree to indemnify the Company from and against any
              claims, liabilities, damages, losses, and expenses, including,
              without limitation, reasonable legal and accounting fees, arising
              directly or indirectly from your breach of any of your obligations
              under these Terms and Conditions. 
            </p>
            <br />
            <h2>Taxes</h2>
            <p>
              79. You understand and agree that you are solely responsible for
              determining your applicable Tax reporting requirements in
              consultation with your tax advisers. Storeo cannot and does not
              offer Tax-related advice to any Users. 
            </p>
            <p>
              80. As a Host, any income that you earn by renting out your space
              on Storeo is considered a taxable income by the CRA or IRS. Storeo
              recommends that you consult with your accountant or tax adviser to
              understand your personal tax obligations, and any tax deductions
              that you may be eligible to claim. As a Storer, Storeo does not
              collect local taxes on your behalf. The tax collected by Storeo
              pertains only to the service fee, and does not cover the Host's
              earnings. Hosts are responsible for managing their own tax
              obligations. 
            </p>
            <br />
            <h2>Contact Information</h2>
            <p>
              If you have any questions about our Terms and Conditions, please
              contact us via <a href="mailto:support@storeo.ca">email</a>.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default TermsAndConditions;
