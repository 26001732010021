import React from "react";
import Reveal from "react-reveal";

const AppBanner = () => {
  return (
    <section className="app_banner_area" id="home">
      <div className="container">
        <div className="row ">
          <div className="col-lg-7">
            <Reveal bottom cascade>
              <div className="app_banner_contentmt mt_40">
                <h2
                  className="f_p f_700 f_size_50 w_color mb_20 wow fadeInLeft"
                  data-wow-delay="0.2s"
                >
                  Self storage 
                  right at your fingertips.
                </h2>
                <p
                  className="f_400 f_size_20 l_height30 w_color wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <br />
                  Store your stuff as little as $7* per month and join the
                  martketplace self storage revolution.
                </p>
                <div className="row justify-content-center" >
                <a href="https://apps.apple.com/app/storeo-reclaim-your-space/id1631766715">

                  <img
                    className="app-store"
                    src={require("../../img/home7/download_on_appstore.svg")}
                    alt="Download on App Store"
                    width="270"
                    height="80"
                    vspace="10"
                    hspace="10"
                  /></a>
                <a href="https://play.google.com/store/apps/details?id=ca.storeo">
                  <img
                    className="play-store"
                    src={require("../../img/home7/download_google_play.svg")}
                    alt="Download on Google Play"
                    width="270"
                    height="80"
                    hspace="10"
                    vspace="10"
                  /></a>
                </div>
                {/* <a href=".#" className="btn_hover mt_30 app_btn wow fadeInLeft" data-wow-delay="0.5s">Download</a> */}
              </div>
            </Reveal>
          </div>

          <div className="col-lg-5">
            <div className="row justify-content-center">
              {/* <img className="app_screen one wow fadeInDown" src={require('../../img/home7/01.png')} alt=""/> */}
              {/* <img className="app_screen two wow fadeInDown" src={require('../../img/home7/03.png')} alt=""/> */}
              {/* <img className="app_screen three wow fadeInDown" src={require('../../img/home7/02.png')} alt=""/> */}
              <img
                className="mobile"
                src={require("../../img/home7/storeo-screenshot.png")}
                alt="mobile screen"
                vspace="20"
              />
            </div>
          </div>
          {/* {/* </div> */}
        </div>
      </div>
    </section>
  );
};

export default AppBanner;
