const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Get In Touch",
            description: "Got a question? We're happy to answer. Send us an email.",
        }
    ],
    AboutWidget: [
        {
          id: 1,
          title: "Download",
          menuItems: [
              
              {
                id: 2,
                url: '#',
                text: 'Android App',
              },
              {
                id: 3,
                url: '#',
                text: 'IOS App',
              },
              
          ],
      },
      {
            id: 2,
            title: "Help",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  text: 'FAQ',
                },
                {
                  id: 2,
                  url: 'https://www.storeo.ca/termsandconditions',
                  text: 'Terms & Conditions',
                },
                // {
                //   id: 3,
                //   url: '#',
                //   text: 'Reporting',
                // },
                // {
                //   id: 4,
                //   url: '#',
                //   text: 'Documentation',
                // },
                // {
                //   id: 5,
                //   url: '#',
                //   text: 'Support Policy',
                // },
                {
                    id: 6,
                    url: 'https://www.storeo.ca/privacypolicy',
                    text: 'Privacy Policy',
                  },
            ],
        },
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Connect With Us",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  icon: 'ti-facebook',
                },
                {
                  id: 2,
                  url: '#',
                  icon: 'ti-twitter-alt',
                },
                {
                  id: 3,
                  url: '#',
                  icon: 'ti-instagram',
                },
               
            ]
        }
    ],
    socialIcon:[
      {
        id: 1,
        url: 'https://www.facebook.com/storeo1',
        icon: 'ti-facebook',
      },
      {
        id: 2,
        url: 'https://www.instagram.com/storeo.ca',
        icon: 'ti-instagram',
      },
      // {
      //   id: 3,
      //   url: '#',
      //   icon: 'ti-vimeo-alt',
      // },
      // {
      //   id: 4,
      //   url: '#',
      //   icon: 'ti-pinterest',
      // }
    ],
    copywrite:"© Storeo Inc 2022 All rights reserved.",
    TestimonialText: [
      {
        id: 1,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 2,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 3,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 4,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      }
    ],
    aboutLinks: [
      {
        id: 1,
        url: '#',
        item: 'Developer',
      },
      {
        id: 2,
        url: '#',
        item: 'Blog',
      },
      {
        id: 3,
        url: '#',
        item: 'Investor',
      },
      {
        id: 4,
        url: '#',
        item: 'Sitemap',
      },
      {
        id: 5,
        url: '#',
        item: 'Jobs',
      }
    ],
    helpLinks: [
      {
        id: 1,
        url: '#',
        item: 'Help and Contact',
      },
      {
        id: 2,
        url: '#',
        item: 'Fees',
      },
      {
        id: 3,
        url: '#',
        item: 'Security',
      },
      {
        id: 4,
        url: '#',
        item: 'App',
      },
      {
        id: 5,
        url: '#',
        item: 'Shop',
      }
    ],
    PrivacyLinks: [
      {
        id: 1,
        url: '#',
        item: 'Privacy Policy',
      },
      {
        id: 2,
        url: '#',
        item: 'Legal Agreement',
      },
      {
        id: 3,
        url: '#',
        item: 'Feedback',
      }
    ],
    about: [
      {
        id: 1,
        url: '#',
        text: 'Who We Are',
      },
      {
        id: 7,
        url: '#',
        text: 'Download Storeo',
      },
      {
        id: 2,
        url: '#',
        text: 'Safety',
      },
      {
        id: 3,
        url: '#',
        text: 'Terms & Conditions',
      },
      {
        id: 4,
        url: '#',
        text: 'Privacy Policy',
      },
      {
        id: 5,
        url: '#',
        text: 'Blog',
      },
      {
        id: 6,
        url: '#',
        text: 'Contact Us',
      },
    ],
    Solution: [
      {
        id: 1,
        url: '#',
        text: 'Download Storeo',
      },
      {
        id: 2,
        url: '#',
        text: 'How it Works',
      },
      {
        id: 4,
        url: '#',
        text: 'Packing Tips',
      },
      {
        id: 3,
        url: '#',
        text: 'FAQs',
      },
      
      // {
      //   id: 5,
      //   url: '#',
      //   text: 'Work Tracking',
      // },
      // {
      //   id: 6,
      //   url: '#',
      //   text: 'See All Uses',
      // },
    ],
    teamSolution: [
      {
        id: 1,
        url: '#',
        text: 'Download Storeo',
      },
      {
        id: 2,
        url: '#',
        text: 'How it Works',
      },
      {
        id: 3,
        url: '#',
        text: 'Storage Tips',
      },
      {
        id: 4,
        url: '#',
        text: 'FAQs',
      },
      // {
      //   id: 5,
      //   url: '#',
      //   text: 'Marketing',
      // },
      // {
      //   id: 6,
      //   url: '#',
      //   text: 'See All team',
      // },
    ],
};
export default FooterData;