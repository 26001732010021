import React, { Component } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

class PrivacyPolicy extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <section className="app_privacy_policy sec_pad pt_150" id="privacy">
         <Helmet>
          <meta charSet="utf-8" />
          <title>Storeo Privacy Policy</title>
          <link
            rel="canonical"
            href="https://www.storeo.ca/privacy"
          />
          <meta
            name="description"
            content="This is Storeo's Privacy Policy outlined for all users, which may be updated from time to time."
          />
          <meta name="keywords" content="Storeo Privacy Policy, Self Storage Marketplace Data Privacy, Data Governance Privacy for Marketplace Self Storage, Crashlytics and data collection for self storage marketplace platform" />
        </Helmet>
        <div className="col-lg-12">
          <div className="container p0">
            <h1>Privacy Policy</h1>

            <h3>Your privacy is very important to us.</h3>
            <br />
            <address>
              Storeo Incorporated
              <br />
              Toronto <br />
              Ontario, Canada
              <br />
            </address>
            <p>
              It is Storeo's policy to respect your privacy regarding any
              information we may collect while operating our website or using
              our mobile application. This Privacy Policy applies to
              www.storeo.ca and our Android and iOS mobile applications
              (hereinafter, "us", "we", “Storeo” or "www.storeo.ca"). We respect
              your privacy and are committed to protecting personally
              identifiable information you may provide us through the Website
              and/or mobile application. We have adopted this privacy policy
              ("Privacy Policy") to explain what information may be collected on
              our mobile applications and our website, how we use this
              information, and under what circumstances we may disclose the
              information to third parties. This Privacy Policy applies only to
              information we collect through our website and our mobile
              applications, and does not apply to our collection of information
              from other sources.
            </p>
            <p>
              This Privacy Policy, together with the Terms and conditions posted
              on our Website and available in our mobile applications, set forth
              the general rules and policies governing your use of our Website
              and mobile applications. Depending on your activities when
              visiting our Website or using our mobile application, you may be
              required to agree to additional terms and conditions.
            </p>
            <h2>Website Visitors</h2>
            <p>
              Like most website and mobile application operators, Storeo
              collects non-personally-identifying information of the sort that
              web browsers and servers typically make available, such as the
              browser type, mobile type, language preference, referring site,
              and the date and time of each visitor request. Storeo's purpose in
              collecting non-personally identifying information is to better
              understand how Storeo's visitors use its website. From time to
              time, Storeo may release non-personally-identifying information in
              the aggregate via Google Analytics, e.g., by publishing a report
              on trends in the usage of its website.
            </p>
            <p>
              Storeo also collects potentially personally-identifying
              information like Internet Protocol (IP) addresses for logged in
              users and for users leaving comments on http://blog.storeo.ca blog
              posts. Storeo only discloses logged in user and commenter IP
              addresses under the same circumstances that it uses and discloses
              personally-identifying information as described below.
            </p>
            <h2>Gathering of Personally-Identifying Information</h2>
            <p>
              Certain visitors to our mobile applications choose to interact
              with Storeo in ways that require Storeo to gather
              personally-identifying information. The amount and type of
              information that Storeo gathers depends on the nature of the
              interaction. Upon joining our platform and marketplace, Storeo
              will require only the required amount of to connect our members
              with others in our platform. For example, Storeo ask visitors who
              sign up using our Website or our mobile applications their name,
              phone number, address and birthdate should they sign up to become
              a Storer. Should visitors choose to join as a Host, additional
              information and pictures of their space will be required.{" "}
            </p>
            <h5>Address</h5>
            <p>
              Storeo collects address information from each customer. This is
              used to confirm their identity (hosts) and to know an address to
              return their items (Storer) should this need arise.{" "}
            </p>
            <h5>Location</h5>
            <p>
              When visitors use Storeo, their location will be requested to
              enhance their experience on the application and to view hosts near
              their location. This location detail is only used at that time,
              and is not saved anywhere.{" "}
            </p>
            <h5>Age</h5>
            <p>
              We strive to ensure that only individuals over the of 18 are able
              to store with us. Therefore, we request the date of birth for our
              users.{" "}
            </p>
            <h2>Security</h2>
            <p>
              The security of your Personal Information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. We always monitor
              that our data access rules to our cloud database are comprehensive
              and up-to-date. While we strive to use commercially acceptable
              means to protect your Personal Information, we cannot guarantee
              its absolute security.
            </p>
            <h2>Links To External Sites</h2>
            <p>
              Our Service may contain links to external sites that are not
              operated by us. If you click on a third party link, you will be
              directed to that third party's site. We strongly advise you to
              review the Privacy Policy and terms and conditions of every site
              you visit.
            </p>
            <p>
              We have no control over, and assume no responsibility for the
              content, privacy policies or practices of any third party sites,
              products or services.
            </p>
            <h2>Protection of Certain Personally-Identifying Information</h2>
            <p>
              Storeo takes great care in handling personally identifying
              information and complies with privacy laws, namely PIPEDA, GDPR
              and CalOPPA.{" "}
            </p>
            <p>
              Storeo discloses potentially personally-identifying and
              personally-identifying information only to those of its employees
              that (i) need to know that information in order to process it on
              Storeo's behalf or to provide services available at Storeo's
              website and mobile applications, and (ii) that have agreed not to
              disclose it to others. Some of those employees may be located
              outside of your home country; by using Storeo's website and app,
              you consent to the transfer of such information to them.
            </p>
            <p>
              Storeo will not rent or sell potentially personally-identifying
              and personally-identifying information to anyone. Other than to
              its employees as described above, Storeo discloses potentially
              personally-identifying and personally-identifying information only
              in response to a subpoena, court order or other governmental
              request, or when Storeo believes in good faith that disclosure is
              reasonably necessary to protect the property or rights of Storeo,
              third parties or the public at large.
            </p>
            <p>
              If you are a registered user on http://www.storeo.ca or our mobile
              application, and have supplied your email address, Storeo may
              occasionally send you an email to tell you about new features,
              solicit your feedback, or just keep you up to date with what's
              going on with Storeo and our products. We primarily use our blog
              to communicate this type of information, so we expect to keep this
              type of email to a minimum. Should you request to unsubscribe from
              our emails, you may use the link provided in your email, or change
              your preferences on our Website or mobile application. If you send
              us a request (for example via a support email or via one of our
              feedback mechanisms), we reserve the right to publish it in order
              to help us clarify or respond to your request or to help us
              support other users. Storeo takes all measures reasonably
              necessary to protect against the unauthorized access, use,
              alteration or destruction of potentially personally-identifying
              and personally-identifying information.
            </p>
            <h2>Firebase Analytics</h2>
            <p>
              Storeo may collect statistics about the behaviour of visitors to
              its website and mobile application. This information will be used
              improve our services and our user interfaces to bring a great
              customer experience for our community. To do this, Storeo uses
              Google’s Firebase Analytics to help learn and enhance the customer
              experience. Google collects information about your use of the app 
              (including your IP address). This data will be transferred and
              saved to one of Google’s cloud servers.
            </p>
            <p>
              Google will use this information for the purpose of evaluating
              your use of the App, compiling reports on the app's activities
              within the App for us, and providing other services relating to
              the use of the App and the internet. Google may also transfer this
              information to third parties where required to do so by law, or
              where such third parties process the information on Google's
              behalf. The data stored at Firebase Analytics is stored for a
              period of 14 months. After this period, Firebase Analytics only
              stores aggregated statistics.
            </p>
            <p>
              For more information about how Google uses your information,
              please see Google's Privacy Policy:
            </p>
            <p>https://www.google.com/policies/privacy</p>
            <h2>Firebase Crashlytics</h2>
            <p>
              We use Google's Firebase Crashlytics to inform us if your app
              crashes and the technical information to us concerning what took
              place to produce this crash. We use this data only in order to
              determine the reason for the crash and to resolve the errors in
              our app. This data is not personal data. Crashlytics is used based
              on our genuine interest in recognizing errors in our app,
              examining them and remedying them. We want you to offer you the
              best possible customer experience.
            </p>
            <h2>Stripe Connect</h2>
            <p>
              Storeo uses Stripe Connect for payment processing. We use secure
              methods to transmit this information. Customers who have signed up
              to be hosts, will need to input their banking details on the
              Stripe website to help get paid. This information is secure, and
              is not shared with us. Stripe will do an identity check as
              required. For Storers, billing information and credit card
              information is securely transmitted to Stripe and is never shared
              with us — we are only notified by the charge that was made on the
              credit card.
            </p>
            <p>
              For more information about how Stripe uses your information,
              please see Stripe's Privacy Policy:
              https://stripe.com/en-my/privacy
            </p>
            <h2>Persona</h2>
            <p>
              Storeo uses Persona for identity verification to build trust in
              our community. We securely connect with Persona to conduct
              verification which includes the sharing of an official government
              issued identification, and a selfie. Currently, only Hosts conduct
              identity verification. This information is processed by Persona,
              and only results of the verification are shared with us.
            </p>
            <p>
              For more information about how Persona uses your information,
              please see Persona's Privacy Policy:
              https://withpersona.com/legal/privacy-policy
            </p>
            <h2>Privacy Policy Changes</h2>
            <p>
              Storeo is always evolving, so our privacy policy will be too.
              Although most privacy changes are likely to be minor, Storeo may change
              its Privacy Policy from time to time, and in our sole discretion.
              Storeo encourages visitors to frequently check this page for any
              changes to its Privacy Policy. Your continued use of this site
              after any change in this Privacy Policy will constitute your
              acceptance of such change.
            </p>
            <h2></h2>
            <p></p>
            <h2>Contact Information</h2>
            <p>
              If you have any questions about this Privacy Policy, or would like to request your data deletion, please
              contact us via <a href="mailto:support@storeo.ca">email</a>.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default PrivacyPolicy;
